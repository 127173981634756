export const CURRENT_CONFIG = {

  // license
  appId: '116031', // You need to go to the development website to apply.
  appKey: '8629c86b9085ac5566a43ca563657a1', // You need to go to the development website to apply.
  appLicense: 'CbKl5H/avz0FVOAWsFYjEQ0So7GmHKR6G8HFjv4bONs+y/jqltzy2DPVvAW9vpBrd06Yw5buQSRBDHgaLR8UEeGVIx7Iayqghc032OaFCHUkYypcXXvPjMSNt/q3Mmc6e1mtdZgeDIXlK7SGZ569m8xWnEv6Ft6y/r6ZPYgZh3I=', // You need to go to the development website to apply.

  // http
  // baseURL: 'http://103.85.84.51:19001/', // This url must end with "/". Example: 'http://192.168.1.1:6789/'线上
  baseURL: 'http://djicloud.zzykhk.com/api/',
  // websocketURL: 'ws://103.85.84.51:19001/api/v1/ws', // Example: 'ws://192.168.1.1:6789/api/v1/ws'
  websocketURL: 'ws://djicloud.zzykhk.com/api/v1/ws',

  // baseURL: 'https://djicloud.zzykhk.com/api/', // This url must end with "/". Example: 'http://192.168.1.1:6789/'线上
  // websocketURL: 'wss://djicloud.zzykhk.com/api/v1/ws', // Example: 'ws://192.168.1.1:6789/api/v1/ws'
  // baseURL: 'http://58.242.156.82:6789/', // This url must end with "/". Example: 'http://192.168.1.1:6789/'线上
  // websocketURL: 'ws://58.242.156.82:6789/api/v1/ws', // Example: 'ws://192.168.1.1:6789/api/v1/ws'
  // baseURL: 'http://192.168.3.108:6789/', // This url must end with "/". Example: 'http://192.168.1.1:6789/'
  aiURL: 'http://103.85.84.51:18355', // ai调用url
  // aiURL: 'http://192.168.3.160:8888', // ai调用url
  // aiURL: 'http://58.242.156.82:8888', // ai调用url
  // livestreaming
  // RTMP Note: This IP is the address of the streaming server. If you want to see livestream on web page, you need to convert the RTMP stream to WebRTC stream.
  rtmpURL: 'rtmp://103.85.84.51:1935/uav/', // Example: 'rtmp://192.168.1.1/live/'

  aiSourceRtspURL: 'rtsp://103.85.84.51:8554/uav/', // AI程序获取接入视频流路径
  aiOutPutRtmpURL: 'rtmp://103.85.84.51:1935/ai/', // AI程序推出视频流路径

  // 流媒体服务器webrtc拉流地址
  webRTCURL: 'http://103.85.84.51:18980/',
  // webRTCURL: 'https://video.zzykhk.com/',
  // webRTCURL: 'http://58.242.156.82:18980/',

  // GB28181 Note:If you don't know what these parameters mean, you can go to Pilot2 and select the GB28181 page in the cloud platform. Where the parameters same as these parameters.
  gbServerIp: 'Please enter the server ip.',
  gbServerPort: 'Please enter the server port.',
  gbServerId: 'Please enter the server id.',
  gbAgentId: 'Please enter the agent id',
  gbPassword: 'Please enter the agent password',
  gbAgentPort: 'Please enter the local port.',
  gbAgentChannel: 'Please enter the channel.',
  // RTSP
  rtspUserName: 'Please enter the username.',
  rtspPassword: 'Please enter the password.',
  rtspPort: '8554',
  // Agora
  agoraAPPID: 'Please enter the agora app id.',
  agoraToken: 'Please enter the agora temporary token.',
  agoraChannel: 'Please enter the agora channel.',

  // map
  // You can apply on the AMap website.
  // amapKey: '38c1812d02b7487b8902abfe6b5b9e18',
  amapKey: 'a3da3e458ef7499b9d9e429040debe3f',

  // 音频文件接口路径，音频文件访问路径
  audioFileAPIUrl: "http://103.85.84.51:10004/fileManage/",
}

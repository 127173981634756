import AMapLoader from '@amap/amap-jsapi-loader'
import { App, reactive } from 'vue'
import { AMapConfig } from '/@/constants/index'

export function useGMapManage () {
  const state = reactive({
    aMap: null, // Map类
    map: null, // 地图对象
    mouseTool: null,
  })

  async function initMap (container: string, app: App, data: any) {
    AMapLoader.load({
      ...AMapConfig
    }).then((AMap) => {
      state.aMap = AMap
      state.map = new AMap.Map(container, {
        center: [111.7212463,27.699984065],
        // center: [116.356820598, 30.811307837],//岳西
        // 放置天地图图层
        layers :[
          new AMap .TileLayer .WMTS({
          url:'http://t4.tianditu.gov.cn/img_w/wmts',
          blend: false,
          tileSize: 256,
          params : {
            Layer :'img',
            Version:'1.0.0',
            Format:'tiles',
            TileMatrixSet:'w',
            STYLE:'default ',
            tk:'ab8ff1ae1ca029103e4dc130b366ce26',
          }
        })
      ],
      // 放置高德卫星图图层
        // layers: [
        //   // 卫星
        //   new AMap.TileLayer.Satellite(),
        //   // 路网
        //   new AMap.TileLayer.RoadNet()
        // ],
        zoom: 16,
        // zoom: 15,
      })
      state.mouseTool = new AMap.MouseTool(state.map)

      // 挂在到全局
      app.config.globalProperties.$aMap = state.aMap
      app.config.globalProperties.$map = state.map
      data.aMaps = state.aMap
      data.maps = state.map
      app.config.globalProperties.$mouseTool = state.mouseTool
    }).catch(e => {
      console.log(e)
    })
  }

  function globalPropertiesConfig (app: App,data:any) {
    initMap('g-container', app,data)
  }

  return {
    globalPropertiesConfig,
  }
}

<template>
  <div class="demo-app">
    <a-config-provider :locale="zhCN">
      <router-view />
    </a-config-provider>
  </div>
</template>

<script lang="ts">
import { computed, defineComponent, ref,watch } from 'vue'
import { useMyStore } from './store'
import GMap from '/@/components/GMap.vue'
import zhCN from 'ant-design-vue/es/locale/zh_CN'
// 全局中文
import moment from 'moment';
import 'moment/dist/locale/zh-cn';
moment.locale('cn');
export default defineComponent({
  name: 'App',
  components: { GMap },

  setup () {
    const store = useMyStore()

    const locale:any = zhCN.locale;
    watch(()=>locale, (val:any) => {
      moment.locale(val);
    });

    return {
      zhCN,

      locale,
      moment,
    }
  }
})
</script>
<style lang="scss" scoped>
.demo-app {
  width: 100%;
  height: 100%;

  .map-wrapper {
    height: 100%;
    width: 100%;
  }
}
</style>

<style lang="scss">
#demo-app {
  width: 100%;
  height: 100%
}
</style>

import store from '/@/store'
import { ref } from 'vue'
import { ELocalStorageKey, EDeviceTypeName } from '/@/types'
import { getDeviceBySn } from '/@/api/manage'
import { message } from 'ant-design-vue'
import dockIcon from '/@/assets/icons/dock.png'
import rcIcon from '/@/assets/icons/rc.png'
import droneIcon from '/@/assets/icons/drone.png'
import { getAngle } from '/@/hooks/use-lnglat-calculate'
import { getLastWaylineByDockSn } from '/@/api/wayline'
import { getApp, getRoot } from '/@/root'
import { log } from 'console'

export function deviceTsaUpdate () {
  const root = getRoot()
  let AMap = root.$aMap
  let line: any = []
  let markerarrys: any = []
  const workspaceId = ref(localStorage.getItem(ELocalStorageKey.WorkspaceId)!)
  const icons = new Map([
    [EDeviceTypeName.Aircraft, droneIcon],
    [EDeviceTypeName.Gateway, rcIcon],
    [EDeviceTypeName.Dock, dockIcon]
  ])
  console.log('store.state.markerInfo.coverMap:', store.state.markerInfo.coverMap)
  const markers = store.state.markerInfo.coverMap === undefined ? [] : store.state.markerInfo.coverMap
  const paths = store.state.markerInfo.pathMap

  let trackLine = null as any

  function getTrackLineInstance () {
    if (!trackLine) {
      trackLine = new AMap.Polyline({
        map: root.$map,
        strokeColor: '#939393' // 线颜色
      })
    }
    return trackLine
  }

  function initIcon (type: number) {
    return new AMap.Icon({
      image: icons.get(type),
      imageSize: new AMap.Size(40, 40),
      size: new AMap.Size(40, 40)
    })
  }

  // 加定位
  function initMarker (type: number, name: string, sn: string, lng?: number, lat?: number) {
    // console.log(`initMarker: type:${type}, name:${name}, sn:${sn}, lng:${lng}, lat:${lat}, root.$aMap:`, root.$aMap)
    // console.log('markers[sn]:', markers[sn])
    // console.log('root.$map:', root.$map)
    if (root.$aMap === undefined) {
      return
    }
    AMap = root.$aMap
    // console.log(type, name, sn, lng, lat, 'type: number, name: string, sn: string, lng?: number, lat?: number')
    if (lng === undefined || lat === undefined) {
      return
    }
    if (markers[sn] !== undefined) {
      // console.log(
      //   'remove makers'
      // )
      root.$map.remove(markers[sn])
    }
    markers[sn] = new AMap.Marker({
      position: new AMap.LngLat(lng, lat),
      icon: initIcon(type),
      title: name,
      anchor: 'top-center',
      offset: [0, -20],
    })
    root.$map.add(markers[sn])
    // console.log(root.$map, 'root.$map')

    // markers[sn].on('moving', function (e: any) {
    //   let path = paths[sn]
    //   if (!path) {
    //     paths[sn] = e.passedPath
    //     return
    //   }
    //   path.push(e.passedPath[0])
    //   path.push(e.passedPath[1])
    //   getTrackLineInstance().setPath(path)
    // })
  }

  function removeMarker (sn: string) {
    if (!markers[sn]) {
      return
    }
    root.$map.remove(markers[sn])
    getTrackLineInstance().setPath([])
    delete markers[sn]
    delete paths[sn]
  }

  function addMarker (sn: string, lng?: number, lat?: number) {
    getDeviceBySn(localStorage.getItem(ELocalStorageKey.WorkspaceId)!, sn)
      .then(data => {
        if (data.code !== 0) {
          message.error(data.message)
          return
        }
        initMarker(data.data.domain, data.data.nickname, sn, lng, lat)
      })
  }

  function moveTo (sn: string, lng: number, lat: number) {
    let marker = markers[sn]
    if (!marker) {
      addMarker(sn, lng, lat)
      marker = markers[sn]
      return
    }
    marker.moveTo([lng, lat], {
      duration: 1800,
      autoRotation: true
    })
  }

  function drawline (path: any) {
    if (line) {
      // 删除航线
      getApp().config.globalProperties.$map.remove(line)
      // 删除航点
      getApp().config.globalProperties.$map.remove(markerarrys)
      markerarrys = []
      line = []
    }
    const polyline = new AMap.Polyline({
      path: path,
      isOutline: true,
      outlineColor: '#ffeeff',
      borderWeight: 0,
      strokeColor: '#11BC82',
      strokeOpacity: 1,
      strokeWeight: 10,
      // 折线样式还支持 'dashed solid'
      strokeStyle: 'solid',
      // strokeStyle是dashed时有效
      strokeDasharray: [10, 5],
      lineJoin: 'round',
      lineCap: 'round',
      zIndex: 50,
      showDir: true,
    })
    polyline.setMap(root.$map)
    line.push(polyline)
    // 缩放地图到合适的视野级别
    // root.$map.setFitView()
    for (let i = 0; i < path.length; i++) {
      // 设置开始点
      const startIcon = new AMap.Icon({
        image: '/icon/triangle.png',
        size: new AMap.Size(40, 40),
        imageSize: new AMap.Size(40, 40),
        // imageOffset: new AMap.Pixel(0, -1),  // 图像相对展示区域的偏移量，适于雪碧图等
      })
      const marker = new AMap.Marker({
        position: path[i],
        offset: new AMap.Pixel(-21, -40),
        icon: startIcon,
        // 设置是否可拖拽
        draggable: false,
        cursor: 'move'
      })
      marker.setMap(root.$map)
      markerarrys.push(marker)
      if (i === 0) {
        marker.setLabel({
          direction: 'center',
          offset: new AMap.Pixel(0, 0), // 设置文本标注偏移量
          content: '<div>' + 'S' + '</div>', // 设置文本标注内容
        })
      } else {
        marker.setLabel({
          direction: 'center',
          offset: new AMap.Pixel(0, 0), // 设置文本标注偏移量
          content: '<div">' + (i + 1) + '</div>', // 设置文本标注内容
        })
      }
    }
  }

  function getLastWaylineByDockSnfn (dockSn: any) {
    getLastWaylineByDockSn(workspaceId.value, dockSn).then(res => {
      if (res.data) {
        drawline(res.data.wayline_point)
      }
    })
  }

  return {
    marker: markers,
    initMarker,
    removeMarker,
    moveTo,
    getLastWaylineByDockSnfn,
    drawline,
  }
}

<template>
  <div class="project-wayline-wrapper height-100">
    <a-spin :spinning="loading" :delay="300" tip="downloading" size="large">
      <div style="height: 50px; line-height: 50px; border-bottom: 1px solid #4f4f4f; font-weight: 450;">
        <a-row>
          <a-col :span="1"></a-col>
          <a-col :span="15">航线库</a-col>
          <a-col :span="3"> </a-col>
          <a-col :span="2" v-if="importVisible" class="flex-row flex-justify-end flex-align-center">
            <a-upload name="file" :multiple="false" :before-upload="beforeUpload" :show-upload-list="false"
              :customRequest="uploadFile">
              <a-button type="text" style="color: white;padding-left:0;padding-right:0">
                <SelectOutlined />
              </a-button>
            </a-upload>
          </a-col>
          <a-col :span="2" v-if="importVisible" class="flex-row flex-justify-end flex-align-center">
            <a-button type="text" style="color: white;padding-left:0;padding-right:0" @click="showModalRoute">
              <PlusOutlined />
            </a-button>
          </a-col>
        </a-row>
      </div>
      <div :style="{ height: height + 'px' }" class="scrollbar">
        <div id="data" class="height-100 uranus-scrollbar" v-if="waylinesData.data.length !== 0" @scroll="onScroll">
          <div v-for="wayline in waylinesData.data" :key="wayline.id">
            <div class="wayline-panel" style="padding-top: 5px;" @click="selectRoute(wayline)">
              <div class="title">
                <a-tooltip :title="wayline.name">
                  <div class="pr10" style="width: 120px; white-space: nowrap; text-overflow: ellipsis; overflow: hidden;">
                    {{ wayline.name }}</div>
                </a-tooltip>
                <div class="ml10">
                  <UserOutlined />
                </div>
                <a-tooltip :title="wayline.user_name">
                  <div class="ml5 pr10"
                    style="width: 80px; white-space: nowrap; text-overflow: ellipsis; overflow: hidden;">{{
                      wayline.user_name }}</div>
                </a-tooltip>
                <div class="fz14" style="margin-right: 4px;" @click="waylineEdir(wayline.id, wayline.name)">
                  <a-dropdown>
                    <a style="color: white;">
                      <EditOutlined />
                    </a>
                  </a-dropdown>
                </div>
                <div class="fz14">
                  <a-dropdown>
                    <a style="color: white;">
                      <EllipsisOutlined />
                    </a>
                    <template #overlay>
                      <a-menu theme="dark" class="more" style="background: #3c3c3c;">
                        <a-menu-item @click="downloadWayline(wayline.id, wayline.name)">
                          <span>下载</span>
                        </a-menu-item>
                        <a-menu-item @click="showWaylineTip(wayline.id)">
                          <span>删除</span>
                        </a-menu-item>
                      </a-menu>
                    </template>
                  </a-dropdown>
                </div>
              </div>
              <div class="ml10 mt5" style="color: hsla(0,0%,100%,0.65);">
                <span>
                  <RocketOutlined />
                </span>
                <span class="ml5">{{ Object.keys(EDeviceType)[Object.values(EDeviceType).indexOf(wayline.drone_model_key)]
                }}</span>
                <span class="ml10">
                  <CameraFilled style="border-top: 1px solid; padding-top: -3px;" />
                </span>
                <span class="ml5" v-for="payload in wayline.payload_model_keys" :key="payload.id">
                  {{ Object.keys(EDeviceType)[Object.values(EDeviceType).indexOf(payload)] }}
                </span>
              </div>
              <div class="mt5 ml10" style="color: hsla(0,0%,100%,0.35);">
                <span class="mr10">更新时间 {{ new Date(wayline.update_time).toLocaleString() }}</span>
              </div>
            </div>
          </div>
        </div>
        <div v-else>
          <a-empty :image-style="{ height: '60px', marginTop: '60px' }" />
        </div>
        <a-modal v-model:visible="deleteTip" width="450px" :closable="false" :maskClosable="false" centered
          :okButtonProps="{ danger: true }" @ok="deleteWayline">
          <p class="pt10 pl20" style="height: 50px;">航线文件一旦删除是不可恢复的，是否继续?</p>
          <template #title>
            <div class="flex-row flex-justify-center">
              <span>删除</span>
            </div>
          </template>
        </a-modal>
      </div>
    </a-spin>
    <a-modal class="newFly" centered :closable="false" wrapClassName="createRoute" v-model:visible="visible"
      title="创建新航线" :maskClosable="false" @ok="handleOk">
      <a-form :model="formState" :label-col="{ span: 24 }">
        <a-form-item label="航线名称">
          <a-input v-model:value="formState.routeName" />
        </a-form-item>
        <a-form-item label="选择飞行器与负载" class="selecItem">
          <a-select v-model:value="formState.load" placeholder="选择飞行器与负载" :options="loadOption" @change="loadChange">
          </a-select>
          <div class="aircraft-contaier">
            <a-dropdown class="wayline-app-ant-dropdown-open" :trigger="['click']">
              <a class="ant-dropdown-link tragger" @click.prevent>
                <div class="payload-card-title flex-container-center">
                  <span class="payload-point"></span>
                  云台 I
                </div>
                <div>
                  <span>{{ formState.camaer.label }}</span>
                </div>
                <DownOutlined />
              </a>
              <template #overlay>
                <a-menu>
                  <a-menu-item v-for="item in camaerOption" :key="item.value">
                    <a href="javascript:;" @click="camerSel(item)">{{ item.label }}</a>
                  </a-menu-item>
                </a-menu>
              </template>
            </a-dropdown>
            <img src="../../../assets/img/aircraft.png" />
          </div>
        </a-form-item>
        <a-divider></a-divider>
        <a-form-item label="航线类型" class="selecItem">
          <!-- <a-select v-model:value="formState.type" placeholder="选择航线类型">
            <a-select-option value="1">航点航线</a-select-option>
          </a-select> -->
          <div class="flex-container">
            <a-button class="isShow">
              <div class="btn-container">
                <img src="/icon/hangdianhangxian.svg" />
                <span>航点航线</span>
              </div>
            </a-button>
            <a-button :disabled="true" class="disabled">
              <div class="btn-container disabled">
                <img src="/icon/mianzhuanghangxian.svg" />
                <span>面状航线</span>
              </div>
            </a-button>
            <a-button :disabled="true" class="disabled">
              <div class="btn-container disabled">
                <img src="/icon/daizhuanghangxian.svg" />
                <span>带状航线</span>
              </div>
            </a-button>
          </div>
        </a-form-item>
      </a-form>
    </a-modal>
  </div>
</template>

<script lang="ts" setup>
import { reactive } from '@vue/reactivity'
import { message } from 'ant-design-vue'
import { onMounted, onUpdated, ref, toRaw, UnwrapRef } from 'vue'
import { deleteWaylineFile, downloadWaylineFile, getWaylineFiles, importKmzFile } from '/@/api/wayline'
import { ELocalStorageKey, ERouterName } from '/@/types'
import { DownOutlined, EllipsisOutlined, RocketOutlined, CameraFilled, UserOutlined, SelectOutlined, PlusOutlined, EditOutlined } from '@ant-design/icons-vue'
import { EDeviceType } from '/@/types/device'
import { useMyStore } from '/@/store'
import { WaylineFile } from '/@/types/wayline'
import { downloadFile } from '/@/utils/common'
import { IPage } from '/@/api/http/type'
import { CURRENT_CONFIG } from '/@/api/http/config'
import { load } from '@amap/amap-jsapi-loader'
import { getRoot } from '/@/root'
import { SelectTypes } from 'ant-design-vue/es/select'

const loading = ref(false)
const store = useMyStore()
const pagination: IPage = {
  page: 1,
  total: -1,
  page_size: 10
}

const waylinesData = reactive({
  data: [] as WaylineFile[]
})

const root = getRoot()
const workspaceId = localStorage.getItem(ELocalStorageKey.WorkspaceId)!
const deleteTip = ref(false)
const deleteWaylineId = ref<string>('')
const canRefresh = ref(true)
const importVisible = ref<boolean>(root.$router.currentRoute.value.name === ERouterName.WAYLINE)
const height = ref()

const visible = ref<boolean>(false)

// M30 52  M30T 53
// Mavic 3E 66  Mavic 3T 67
const loadOption = ref<SelectTypes['options']>([
  {
    value: 67,
    label: 'M30系列',
    datas: [{
      label: 'M30相机',
      value: 52,
      droneSubEnumValue: 0
    }, {
      label: 'M30T 相机',
      value: 53,
      droneSubEnumValue: 1
    }]
  },
  {
    value: 77,
    label: 'Mavic 3 行业系列',
    datas: [{
      label: 'Mavic 3E 相机',
      value: 66,
      droneSubEnumValue: 0
    }, {
      label: 'Mavic 3T 相机',
      value: 67,
      droneSubEnumValue: 1
    }, {
      label: 'Mavic 3TD 相机',
      value: 91,
      droneSubEnumValue: 1
    }]
  }
])
const camaerOption = ref([{
  label: 'M30相机',
  value: 52,
  droneSubEnumValue: 0
}, {
  label: 'M30T 相机',
  value: 53,
  droneSubEnumValue: 1
}])

const showModalRoute = () => {
  visible.value = true
}

const handleOk = (e: MouseEvent) => {
  const item = waylinesData.data.find(item => item.name === formState.routeName)
  if (!item) {
    root.$router.push({
      path: ERouterName.ROUTE_BUILT,
      query: {
        type: 'add',
        payloadInfo: JSON.stringify(formState)
      }
    })
  } else {
    message.info('航线名称已存在，请更换航线名称')
  }
  console.log('item', item)
  // visible.value = false
  // root.$router.push(ERouterName.ROUTE_BUILT + '?payloadInfo=' + JSON.stringify(formState))
}

interface FormState {
  routeName: string;
  load: string | undefined;
  camaer: Object;
  type: string | undefined;
  droneInfo: Object;
}
// 相机选择
const formState: UnwrapRef<FormState> = reactive({
  routeName: '新建航线10',
  camaer: {
    label: 'M30相机',
    value: 52
  },
  load: 'M30系列',
  type: '1',
  droneInfo: {
    droneEnumValue: 67,
    droneSubEnumValue: 0
  }
})
const onSubmit = () => {
  console.log('submit!', toRaw(formState))
}

onMounted(() => {
  const parent = document.getElementsByClassName('scrollbar').item(0)?.parentNode as HTMLDivElement
  height.value = document.body.clientHeight - parent.firstElementChild!.clientHeight -100
  getWaylines()

  const key = setInterval(() => {
    const data = document.getElementById('data')?.lastElementChild as HTMLDivElement
    if (pagination.total === 0 || Math.ceil(pagination.total / pagination.page_size) <= pagination.page || height.value <= data?.clientHeight + data?.offsetTop) {
      clearInterval(key)
      return
    }
    pagination.page++
    getWaylines()
  }, 1000)
})
// 选择飞行器与负载
function loadChange(value: string) {
  camaerOption.value = loadOption.value.find(item => item.value === value).datas
  formState.load = loadOption.value.find(item => item.value === value)?.label
  formState.camaer = camaerOption.value[0]
  formState.droneInfo.droneEnumValue = value
  formState.droneInfo.droneSubEnumValue = camaerOption.value[0].droneSubEnumValue
  // formState.droneInfo = {
  //   droneEnumValue: value,
  //   droneSubEnumValue: ''
  // }
}
// 选择相机
function camerSel(value: string) {
  formState.camaer = value
  formState.droneInfo.droneSubEnumValue = value.droneSubEnumValue
}

function getWaylines() {
  if (!canRefresh.value) {
    return
  }
  canRefresh.value = false
  getWaylineFiles(workspaceId, {
    page: pagination.page,
    page_size: pagination.page_size,
    order_by: 'update_time desc'
  }).then(res => {
    if (res.code !== 0) {
      return
    }
    waylinesData.data = [...waylinesData.data, ...res.data.list]
    if (waylinesData.data.length > 0) {
      const item = waylinesData.data.find(item => item.name.includes('新建航线'))
      if (item) {
        const index = item.name.split('新建航线')
        // console.log('ids', item, index[1])
        formState.routeName = '新建航线' + (parseInt(index[1]) + 1)
      } else {
        formState.routeName = '新建航线1'
      }
    }
    pagination.total = res.data.pagination.total
    pagination.page = res.data.pagination.page
  }).finally(() => {
    canRefresh.value = true
  })
}

function showWaylineTip(waylineId: string) {
  deleteWaylineId.value = waylineId
  deleteTip.value = true
}

function deleteWayline() {
  deleteWaylineFile(workspaceId, deleteWaylineId.value).then(res => {
    if (res.code === 0) {
      message.success('Wayline file deleted')
    }
    deleteWaylineId.value = ''
    deleteTip.value = false
    pagination.total = 0
    pagination.page = 1
    waylinesData.data = []
    getWaylines()
  })
}

function downloadWayline(waylineId: string, fileName: string) {
  loading.value = true
  downloadWaylineFile(workspaceId, waylineId).then(res => {
    if (!res) {
      return
    }
    const data = new Blob([res], { type: 'application/zip' })
    downloadFile(data, fileName + '.kmz')
  }).finally(() => {
    loading.value = false
  })
}

// 航点编辑
function waylineEdir(waylineId: string, fileName: string) {
  console.log(waylineId, fileName)
  root.$router.push({
    path: ERouterName.ROUTE_BUILT,
    query: {
      type: 'updata',
      waylineId: waylineId
    }
  })
}

function selectRoute(wayline: WaylineFile) {
  store.commit('SET_SELECT_WAYLINE_INFO', wayline)
}

function onScroll(e: any) {
  const element = e.srcElement
  if (element.scrollTop + element.clientHeight >= element.scrollHeight - 5 && Math.ceil(pagination.total / pagination.page_size) > pagination.page && canRefresh.value) {
    pagination.page++
    getWaylines()
  }
}

interface FileItem {
  uid: string;
  name?: string;
  status?: string;
  response?: string;
  url?: string;
}

interface FileInfo {
  file: FileItem;
  fileList: FileItem[];
}
const fileList = ref<FileItem[]>([])

function beforeUpload(file: FileItem) {
  fileList.value = [file]
  loading.value = true
  return true
}
const uploadFile = async () => {
  fileList.value.forEach(async (file: FileItem) => {
    const fileData = new FormData()
    fileData.append('file', file, file.name)
    await importKmzFile(workspaceId, fileData).then((res) => {
      if (res.code === 0) {
        message.success(`${file.name} file uploaded successfully`)
        canRefresh.value = true
        pagination.total = 0
        pagination.page = 1
        waylinesData.data = []
        getWaylines()
      }
    }).finally(() => {
      loading.value = false
      fileList.value = []
    })
  })
}

</script>

<style lang="scss" scoped>
.wayline-panel {
  background: #3c3c3c;
  margin-left: auto;
  margin-right: auto;
  margin-top: 10px;
  height: 90px;
  width: 95%;
  font-size: 13px;
  border-radius: 2px;
  cursor: pointer;

  .title {
    display: flex;
    flex-direction: row;
    align-items: center;
    height: 30px;
    font-weight: bold;
    margin: 0px 10px 0 10px;
  }
}

.uranus-scrollbar {
  overflow: auto;
  scrollbar-width: thin;
  scrollbar-color: #c5c8cc transparent;
}

.selecItem {
  .aircraft-contaier {
    position: relative;
    margin-top: -15px;
    padding: 0 18px 0;

    .wayline-app-ant-dropdown-open {
      position: absolute;
      width: 150px;
      height: 48px;
      right: 50%;
      transform: translateX(50%);
      bottom: 4px;
      background: rgba(0, 0, 0, .5);
      border: 1px solid hsla(0, 0%, 100%, .2);
      border-radius: 2px;
      padding: 4px;
      cursor: pointer;
      color: #fff !important;
      font-size: 12px;
      line-height: 20px;
      text-align: center;
    }

    .wayline-app-ant-dropdown-open:hover {
      background-color: rgba(92, 173, 255, .2);
    }
  }

  .flex-container {
    display: flex;

    .ant-btn {
      margin-right: 32px;
      width: 100px;
      height: 100px;
      background-color: transparent;
      border: 1px solid hsla(0, 0%, 100%, .2);

      .btn-container {
        margin: auto;
        // width: 100px;
        height: 56px;
        color: #fff;
        display: flex;
        flex-direction: column;
        align-items: center;

        img {
          width: 40px;
          height: 40px;
        }
      }

      .disabled {
        color: #fff;

      }
    }

    .isShow {
      border: 1px solid #2b85e4;
    }

    .isShow:hover {
      background-color: #1890ff40;
    }

    .disabled {
      background-color: #3c3c3c;
      opacity: .5;
      border-radius: 2px;
    }
  }
}

.ant-divider {
  background-color: #494949;
}

img {
  width: 100%;
  max-height: 214px;
}

.ant-input {
  background-color: #000 !important;
  color: #fff !important;
  border-color: #4f4f4f !important;
}

::v-deep .ant-select-selector {
  background: #3c3c3c !important;
  border: 1px solid transparent !important;
  color: #fff;
  box-shadow: none;
}

::v-deep .ant-select-suffix {
  color: #fff;
}

::v-deep .ant-modal-body {
  padding-bottom: 0 !important;
}
</style>
<style lang="scss">
.createRoute {
  .ant-modal-content {
    background-color: #1c1c1c;
    color: '#fff'
  }

  .ant-modal-header {
    background-color: #282828;
    color: #fff;
    padding: 10px 24px;
    border-bottom: none !important;

    .ant-modal-title {
      color: #fff;
      text-align: center;
    }
  }

  .ant-modal-footer {
    // border-top: 1px solid #494949 !important;
    border-top: none;
  }

  .ant-form-item-label {
    label {
      color: #ffffff73 !important;
    }
  }
}

.newFly .ant-modal-body {
  padding-bottom: 0 !important;
}
</style>

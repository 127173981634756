<template>
  <div class="Gmap">
    <div v-if="getWaylineRoute()" class="g-map-wrapper">
    <iframe id="routePlanning" src="../../assets/html/routePlanning.html" scrolling="auto" frameborder="0"
      v-show="getWaylineRoute()" :style="{ width: '100%', height: '100%' }"></iframe>
    <!-- <div  id="g-container" v-show="!getWaylineRoute()" :style="{ width: '100%', height: '100%' }" /> -->
    <div class="g-action-panle" :style="{ right: drawVisible ? '316px' : '16px' }">
      <div class="g-action-item" @click="draw('pin', true)">
        <a-button type="primary">PIN</a-button>
      </div>
      <div class="g-action-item" @click="draw('polyline', true)">
        <a-button type="primary">Line</a-button>
      </div>
      <div class="g-action-item" @click="draw('polygon', true)">
        <a-button type="primary">Poly</a-button>
      </div>
      <div v-if="mouseMode" class="g-action-item" @click="draw('off', false)">
        <a-button type="primary" danger>X</a-button>
      </div>
    </div>
  </div>
  <div v-else class="g-map-wrapper">
    <div id="g-container" :style="{ width: '100%', height: '100%' }" />
    <div class="g-action-panle" :style="{ right: drawVisible ? '316px' : '16px' }">
      <div :class="state.currentType === 'pin' ? 'g-action-item selection' : 'g-action-item'" @click="draw('pin', true)">
        <a><a-image :src="pin" :preview="false" /></a>
      </div>
      <div :class="state.currentType === 'polyline' ? 'g-action-item selection' : 'g-action-item'"
        @click="draw('polyline', true)">
        <a>
          <LineOutlined :rotate="135" class="fz20" />
        </a>
      </div>
      <div :class="state.currentType === 'polygon' ? 'g-action-item selection' : 'g-action-item'"
        @click="draw('polygon', true)">
        <a>
          <BorderOutlined class="fz18" />
        </a>
      </div>
      <div v-if="mouseMode" class="g-action-item" @click="draw('off', false)">
        <a style="color: red;">
          <CloseOutlined />
        </a>
      </div>
    </div>
    <!-- 单独飞机的 -->
    <div v-if="osdVisible.visible && !osdVisible.is_dock" class="osd-panel fz12">
    <!-- <div  class="osd-panel fz12"> -->
      <div class="pl5 pr5 flex-align-center flex-row flex-justify-between"
        style="border-bottom: 1px solid #515151; height: 18%;">
        <span>{{ osdVisible.callsign }}</span>
        <span><a class="fz16" style="color: white;" @click="() => osdVisible.visible = false">
            <CloseOutlined />
          </a></span>
      </div>
      <div style="height: 82%;">
        <div class="flex-column flex-align-center flex-justify-center"
          style="margin-top: -5px; padding-top: 25px; float: left; width: 60px; background: #2d2d2d;">
          <a-tooltip :title="osdVisible.model">
            <div style="width: 90%;" class="flex-column flex-align-center flex-justify-center">
              <span><a-image :src="M30" :preview="false" /></span>
              <span>{{ osdVisible.model }}</span>
            </div>
          </a-tooltip>
        </div>
        <div class="osd">
          <a-row>
            <a-col span="16"
              :style="deviceInfo.device.mode_code === EModeCode.Disconnected ? 'color: red; font-weight: 700;' : 'color: rgb(25,190,107)'">{{
                EModeCode[deviceInfo.device.mode_code] }}</a-col>
          </a-row>
          <a-row>
            <a-col span="6">
              <a-tooltip title="Signal strength">
                <span>HD</span>
                <span class="ml10">{{ deviceInfo.gateway?.transmission_signal_quality }}</span>
              </a-tooltip>
            </a-col>
            <a-col span="6">
              <a-tooltip title="RC Battery Level">
                <span>
                  <ThunderboltOutlined class="fz14" />
                </span>
                <span class="ml10">{{ deviceInfo.gateway && deviceInfo.gateway.capacity_percent !== str ?
                  deviceInfo.gateway.capacity_percent + ' %' : deviceInfo.gateway.capacity_percent }}</span>
              </a-tooltip>
            </a-col>

            <a-col span="6">
              <a-tooltip title="Drone Battery Level">
                <span>
                  <ThunderboltOutlined class="fz14" />
                </span>
                <span class="ml10">{{ deviceInfo.device.battery.capacity_percent !== str ?
                  deviceInfo.device.battery.capacity_percent + ' %' : deviceInfo.device.battery.capacity_percent }}</span>
              </a-tooltip>
            </a-col>
          </a-row>
          <a-row>
            <a-tooltip title="RTK Fixed">
              <a-col span="6" class="flex-row flex-align-center flex-justify-start">
                <span>Fixed</span>
                <span class="ml10 circle"
                  :style="deviceInfo.device.position_state.is_fixed === 1 ? 'backgroud: rgb(25,190,107);' : ' background: red;'"></span>
              </a-col>
            </a-tooltip>
            <a-col span="6">
              <a-tooltip title="GPS">
                <span>GPS</span>
                <span class="ml10">{{ deviceInfo.device.position_state.gps_number }}</span>
              </a-tooltip>
            </a-col>
            <a-col span="6">
              <a-tooltip title="RTK">
                <span>
                  <TrademarkOutlined class="fz14" />
                </span>
                <span class="ml10">{{ deviceInfo.device.position_state.rtk_number }}</span>
              </a-tooltip>
            </a-col>
          </a-row>
          <a-row>
            <a-col span="6">
              <a-tooltip title="Flight Mode">
                <span>
                  <ControlOutlined class="fz16" />
                </span>
                <span class="ml10">{{ EGear[deviceInfo.device.gear] }}</span>
              </a-tooltip>
            </a-col>
            <a-col span="6">
              <a-tooltip title="Altitude above sea level">
                <span>ASL</span>
                <span class="ml10">{{ deviceInfo.device.height === str ? str : deviceInfo.device.height.toFixed(2) +
                  'm' }}</span>
              </a-tooltip>
            </a-col>
            <a-col span="6">
              <a-tooltip title="Altitude above takeoff level">
                <span>ALT</span>
                <span class="ml10">{{ deviceInfo.device.elevation === str ? str : deviceInfo.device.elevation.toFixed(2) +
                  ' m' }}</span>
              </a-tooltip>
            </a-col>
            <a-col span="6">
              <a-tooltip title="Distance to Home Point">
                <span>H</span>
                <span class="ml10">{{ deviceInfo.device.home_distance === str ? str :
                  deviceInfo.device.home_distance.toFixed(2) + ' m' }}</span>
              </a-tooltip>
            </a-col>
          </a-row>
          <a-row>
            <a-col span="6">
              <a-tooltip title="Horizontal Speed">
                <span>H.S</span>
                <span class="ml10">{{ deviceInfo.device.horizontal_speed === str ? str :
                  deviceInfo.device.horizontal_speed.toFixed(2) + ' m/s' }}</span>
              </a-tooltip>
            </a-col>
            <a-col span="6">
              <a-tooltip title="Vertical Speed">
                <span>V.S</span>
                <span class="ml10">{{ deviceInfo.device.vertical_speed === str ? str :
                  deviceInfo.device.vertical_speed.toFixed(2) + ' m/s' }}</span>
              </a-tooltip>
            </a-col>
            <a-col span="6">
              <a-tooltip title="Wind Speed">
                <span>W.S</span>
                <span class="ml10">{{ deviceInfo.device.wind_speed === str ? str : (deviceInfo.device.wind_speed /
                  10).toFixed(2) + ' m/s' }}</span>
              </a-tooltip>
            </a-col>

          </a-row>

          <div>
            <a-row class="p5">
            <a-col span="6">
              <a-button :type="activeKey1" :disabled="dockControlPanelVisible" size="small" @click="setactiveKey ('activeKey1')">
                飞行相机11
              </a-button>
            </a-col>
            <a-col span="6">
              <a-button :type="activeKey2" :disabled="dockControlPanelVisible" size="small" @click="setactiveKey('activeKey2')">
                M30T相机
              </a-button>
            </a-col>
          </a-row>
          </div>
          <div id="airlivediv" v-show="activeKey1==='primary'" style="height: 12.5rem;width: 100%;padding-left: 0;">
          </div>
          <!-- <iframe id="airlive"  src="assets/html/airlive.html" scrolling="auto" frameborder="0" :style="{ width: '100%', height: '100%' }"></iframe> -->
          <div id="cameralivediv" v-show="activeKey2==='primary'"   style="height: 12.5rem;width: 100%;padding-left: 0;">
          </div>
          <!-- <iframe id="cameralive" src="assets/html/cameralive.html" scrolling="auto" frameborder="0" :style="{ width: '100%', height: '100%' }"></iframe> -->
        </div>
      </div>
      <div class="battery-slide" v-if="deviceInfo.device.battery.remain_flight_time !== 0">
        <div style="background: #535759;" class="width-100"></div>
        <div class="capacity-percent" :style="{ width: deviceInfo.device.battery.capacity_percent + '%' }"></div>
        <div class="return-home" :style="{ width: deviceInfo.device.battery.return_home_power + '%' }"></div>
        <div class="landing" :style="{ width: deviceInfo.device.battery.landing_power + '%' }"></div>
        <div class="white-point" :style="{ left: deviceInfo.device.battery.landing_power + '%' }"></div>
        <div class="battery" :style="{ left: deviceInfo.device.battery.capacity_percent + '%' }">
          {{ Math.floor(deviceInfo.device.battery.remain_flight_time / 60) }}:
          {{ 10 > (deviceInfo.device.battery.remain_flight_time % 60) ? '0' :
            '' }}{{ deviceInfo.device.battery.remain_flight_time % 60 }}
        </div>
      </div>
    </div>
    <!-- 机场OSD -->
    <div v-if="osdVisible.visible && osdVisible.is_dock" class="osd-panel fz12">
    <!-- <div class="osd-panel fz12 " > -->
      <div class="fz16 pl5 pr5 flex-align-center flex-row flex-justify-between"
        style="border-bottom: 1px solid #515151; height: 10%;">
        <span>{{ osdVisible.gateway_callsign }}</span>
        <span><a style="color: white;" @click="() => osdVisible.visible = false">
            <CloseOutlined />
          </a></span>
      </div>
      <!-- 机场 -->
      <div class="flex-display" style="border-bottom: 1px solid #515151;">
        <div class="flex-column flex-align-stretch flex-justify-center" style="width: 60px; background: #2d2d2d;">
          <a-tooltip :title="osdVisible.model">
            <div class="flex-column  flex-align-center flex-justify-center" style="width: 90%;">
              <span>
                <RobotFilled style="font-size: 48px;" />
              </span>
              <span class="mt10">Dock</span>
            </div>
          </a-tooltip>
        </div>
        <div class="osd flex-1" style="flex: 1">
          <a-row>
            <a-col span="16"
              :style="deviceInfo.dock.basic_osd?.mode_code === EDockModeCode.Disconnected ? 'color: red; font-weight: 700;' : 'color: rgb(25,190,107)'">
              {{ EDockModeCode[deviceInfo.dock.basic_osd?.mode_code] }}</a-col>
          </a-row>
          <a-row>
            <a-col span="12">
              <a-tooltip title="累计运行时间">
                <span>
                  <HistoryOutlined />
                </span>
                <span class="ml10">
                  <span v-if="deviceInfo.dock.work_osd?.acc_time >= 2592000"> {{
                    Math.floor(deviceInfo.dock.work_osd?.acc_time / 2592000) }}m </span>
                  <span v-if="(deviceInfo.dock.work_osd?.acc_time % 2592000) >= 86400"> {{
                    Math.floor((deviceInfo.dock.work_osd?.acc_time % 2592000) / 86400) }}d </span>
                  <span v-if="(deviceInfo.dock.work_osd?.acc_time % 2592000 % 86400) >= 3600"> {{
                    Math.floor((deviceInfo.dock.work_osd?.acc_time % 2592000 % 86400) / 3600) }}h </span>
                  <span v-if="(deviceInfo.dock.work_osd?.acc_time % 2592000 % 86400 % 3600) >= 60"> {{
                    Math.floor((deviceInfo.dock.work_osd?.acc_time % 2592000 % 86400 % 3600) / 60) }}min </span>
                  <span>{{ Math.floor(deviceInfo.dock.work_osd?.acc_time % 2592000 % 86400 % 3600 % 60) }} s</span>
                </span>
              </a-tooltip>
            </a-col>
            <a-col span="12">
              <a-tooltip title="激活时间">
                <span>
                  <FieldTimeOutlined />
                </span>
                <span class="ml10">{{ new Date((deviceInfo.dock.work_osd?.activation_time ?? 0) * 1000).toLocaleString()
                }}
                </span>
              </a-tooltip>
            </a-col>
          </a-row>
          <a-row>
            <a-col span="6">
              <a-tooltip title="网络状态">
                <span
                  :style="deviceInfo.dock.basic_osd?.network_state?.type === NetworkStateTypeEnum.ETHERNET || deviceInfo.dock.basic_osd?.network_state?.quality === NetworkStateQualityEnum.GOOD ?
                    'color: #00ee8b' : deviceInfo.dock.basic_osd?.network_state?.quality === NetworkStateQualityEnum.MEDIUM ? 'color: yellow' : 'color: red'">
                  <span v-if="deviceInfo.dock.basic_osd?.network_state?.type === NetworkStateTypeEnum.FOUR_G">
                    <SignalFilled />
                  </span>
                  <span v-else>
                    <GlobalOutlined />
                  </span>
                </span>
                <span class="ml10">{{ deviceInfo.dock.basic_osd?.network_state?.rate }} kb/s</span>
              </a-tooltip>
            </a-col>
            <a-col span="6">
              <a-tooltip title="该机场执行任务的总次数">
                <span>
                  <CarryOutOutlined />
                </span>
                <span class="ml10">{{ deviceInfo.dock.work_osd?.job_number }} </span>
              </a-tooltip>
            </a-col>
            <a-col span="6">
              <a-tooltip title="媒体文件上传">
                <span>
                  <CloudUploadOutlined class="fz14" />
                </span>
                <span class="ml10">{{ deviceInfo.dock.link_osd?.media_file_detail?.remain_upload }}</span>
              </a-tooltip>
            </a-col>
            <a-col span="6">
              <a-tooltip>
                <template #title>
                  <p>总量: {{ deviceInfo.dock.basic_osd?.storage?.total }}</p>
                  <p>已使用: {{ deviceInfo.dock.basic_osd?.storage?.used }}</p>
                </template>
                <span>
                  <FolderOpenOutlined />
                </span>
                <span class="ml10" v-if="deviceInfo.dock.basic_osd?.storage?.total > 0">
                  <a-progress type="circle" :width="20"
                    :percent="deviceInfo.dock.basic_osd?.storage?.used * 100 / deviceInfo.dock.basic_osd?.storage?.total"
                    :strokeWidth="20" :showInfo="false"
                    :strokeColor="deviceInfo.dock.basic_osd?.storage?.used * 100 / deviceInfo.dock.basic_osd?.storage?.total > 80 ? 'red' : '#00ee8b'" />
                </span>
              </a-tooltip>
            </a-col>
          </a-row>
          <a-row>
            <a-col span="6">
              <a-tooltip title="风速">
                <span>W.S</span>
                <span class="ml10">{{ (deviceInfo.dock.basic_osd?.wind_speed ?? str) + ' m/s' }}</span>
              </a-tooltip>
            </a-col>
            <a-col span="6">
              <a-tooltip title="降雨">
                <span>🌧</span>
                <span class="ml10">{{ RainfallEnum[deviceInfo.dock.basic_osd?.rainfall] }}</span>
              </a-tooltip>
            </a-col>
            <a-col span="6">
              <a-tooltip title="环境温度">
                <span>°C</span>
                <span class="ml10">{{ deviceInfo.dock.basic_osd?.environment_temperature }}</span>
              </a-tooltip>
            </a-col>
            <a-col span="6">
              <a-tooltip title="机场温度">
                <span>°C</span>
                <span class="ml10">{{ deviceInfo.dock.basic_osd?.temperature }}</span>
              </a-tooltip>
            </a-col>
          </a-row>
          <a-row>
            <a-col span="6">
              <a-tooltip title="机场湿度">
                <span>💦</span>
                <span class="ml10">{{ deviceInfo.dock.basic_osd?.humidity }}</span>
              </a-tooltip>
            </a-col>
            <a-col span="6">
              <a-tooltip title="工作电压">
                <span
                  style="border: 1px solid; border-radius: 50%; width: 18px; height: 18px; line-height: 16px; text-align: center; float: left;">V</span>
                <span class="ml10">{{ (deviceInfo.dock.work_osd?.working_voltage ?? str) + ' mV' }}</span>
              </a-tooltip>
            </a-col>
            <a-col span="6">
              <a-tooltip title="工作电流">
                <span
                  style="border: 1px solid; border-radius: 50%; width: 18px; height: 18px; line-height: 15px; text-align: center; float: left;">A</span>
                <span class="ml10">{{ (deviceInfo.dock.work_osd?.working_current ?? str) + ' mA' }}</span>
              </a-tooltip>
            </a-col>
            <a-col span="6">
              <a-tooltip title="无人机在机场">
                <span>
                  <RocketOutlined />
                </span>
                <span class="ml10">{{ DroneInDockEnum[deviceInfo.dock.basic_osd?.drone_in_dock] }}</span>
              </a-tooltip>
            </a-col>
          </a-row>
          <a-row class="p5">
            <a-col span="6">
              <a-button type="primary" :disabled="dockControlPanelVisible" size="small" @click="setDockControlPanelVisible(true)">
                设备操作
                </a-button>
            </a-col>
            <a-col span="6">
              <a-button type="primary" :disabled="dockControlPanelVisible" size="small" @click="toCockpit()">
                进入驾驶舱
                </a-button>
            </a-col>
            <a-col span="6">
              <a-button :type="osdVisible.activeKey3" :disabled="dockControlPanelVisible" size="small" @click="setactiveKey('activeKey3')">
                监控
              </a-button>
              <!-- <a-button  @click="routepush()">
                去掉航线
              </a-button> -->
            </a-col>

          </a-row>
          <div id="docklivediv" v-show="osdVisible.activeKey3==='primary'"  style="height: 12.5rem;width: 100%;padding-left: 0;">
            <iframe id="docklive" src="assets/html/docklive.html" scrolling="auto" frameborder="0" :style="{ width: '100%', height: '100%' }"></iframe>
          </div>
          <!-- 机场控制面板 -->
          <DockControlPanel v-if="dockControlPanelVisible" :sn="osdVisible.gateway_sn"  :deviceInfo="deviceInfo" @close-panel="setDockControlPanelVisible" @close-control-panel="onCloseControlPanel">
            </DockControlPanel>
        </div>
      </div>
      <!--  飞机-->
      <div class="flex-display">
        <div class="flex-column flex-align-stretch flex-justify-center" style="width: 60px;  background: #2d2d2d;">
          <a-tooltip :title="osdVisible.model">
            <div style="width: 90%;" class="flex-column flex-align-center flex-justify-center">
              <span><a-image :src="M30" :preview="false" /></span>
              <span>M30</span>
            </div>
          </a-tooltip>
        </div>
        <div class="osd flex-1">
          <a-row>
            <a-col span="16"
              :style="!deviceInfo.device || deviceInfo.device?.mode_code === EModeCode.Disconnected ? 'color: red; font-weight: 700;' : 'color: rgb(25,190,107)'">
              {{ !deviceInfo.device ? EModeCode[EModeCode.Disconnected] : EModeCode[deviceInfo.device?.mode_code]
              }}</a-col>
          </a-row>
          <a-row>
            <a-col span="6">
              <a-tooltip title="上传速度">
                <span>
                  <SignalFilled />
                  <ArrowUpOutlined style="font-size: 9px; vertical-align: top;" />
                </span>
                <span class="ml10">{{ deviceInfo.dock.link_osd?.sdr?.up_quality }}</span>
              </a-tooltip>
            </a-col>
            <a-col span="6">
              <a-tooltip title="下载速度">
                <span>
                  <SignalFilled />
                  <ArrowDownOutlined style="font-size: 9px; vertical-align: top;" />
                </span>
                <span class="ml10">{{ deviceInfo.dock.link_osd?.sdr?.down_quality }}</span>
              </a-tooltip>
            </a-col>
            <a-col span="6">
              <a-tooltip title="无人机电池电量">
                <span>
                  <ThunderboltOutlined class="fz14" />
                </span>
                <span class="ml10">{{ deviceInfo.device && deviceInfo.device.battery.capacity_percent !== str ?
                  deviceInfo.device?.battery.capacity_percent + ' %' : str }}</span>
              </a-tooltip>
            </a-col>
            <a-col span="6">
              <a-tooltip>
                <template #title>
                  <p>总量: {{ deviceInfo.device?.storage?.total }}</p>
                  <p>已使用: {{ deviceInfo.device?.storage?.used }}</p>
                </template>
                <span>
                  <FolderOpenOutlined />
                </span>
                <span class="ml10" v-if="deviceInfo.device?.storage?.total > 0">
                  <a-progress type="circle" :width="20"
                    :percent="deviceInfo.device?.storage?.used * 100 / deviceInfo.device?.storage?.total"
                    :strokeWidth="20" :showInfo="false"
                    :strokeColor="deviceInfo.device?.storage?.used * 100 / deviceInfo.device?.storage?.total > 80 ? 'red' : '#00ee8b'" />
                </span>
              </a-tooltip>
            </a-col>
          </a-row>
          <a-row>
            <a-tooltip title="RTK固定">
              <a-col span="6" class="flex-row flex-align-center flex-justify-start">
                <span>Fixed</span>
                <span class="ml10 circle"
                  :style="deviceInfo.device?.position_state.is_fixed === 1 ? 'backgroud: rgb(25,190,107);' : ' background: red;'"></span>
              </a-col>
            </a-tooltip>
            <a-col span="6">
              <a-tooltip title="GPS">
                <span>GPS</span>
                <span class="ml10">{{ deviceInfo.device ? deviceInfo.device.position_state.gps_number : str }}</span>
              </a-tooltip>
            </a-col>
            <a-col span="6">
              <a-tooltip title="RTK">
                <span>
                  <TrademarkOutlined class="fz14" />
                </span>
                <span class="ml10">{{ deviceInfo.device ? deviceInfo.device.position_state.rtk_number : str }}</span>
              </a-tooltip>
            </a-col>
          </a-row>
          <a-row>
            <a-col span="6">
              <a-tooltip title="飞行模式">
                <span>
                  <ControlOutlined class="fz16" />
                </span>
                <span class="ml10">{{ deviceInfo.device ? EGear[deviceInfo.device?.gear] : str }}</span>
              </a-tooltip>
            </a-col>
            <a-col span="6">
              <a-tooltip title="海拔高度">
                <span>ASL</span>
                <span class="ml10">{{ !deviceInfo.device || deviceInfo.device.height === str ? str :
                  deviceInfo.device?.height.toFixed(2) + ' m' }}</span>
              </a-tooltip>
            </a-col>
            <a-col span="6">
              <a-tooltip title="相对起飞高度">
                <span>ALT</span>
                <span class="ml10">{{ !deviceInfo.device || deviceInfo.device.elevation === str ? str :
                  deviceInfo.device?.elevation.toFixed(2) + ' m' }}</span>
              </a-tooltip>
            </a-col>
            <a-col span="6">
              <a-tooltip title="到起飞点的距离">
                <span
                  style="border: 1px solid; border-radius: 50%; width: 18px; height: 18px; line-height: 15px; text-align: center;  display: block; float: left;">H</span>
                <span class="ml10">{{ !deviceInfo.device || deviceInfo.device.home_distance === str ? str :
                  deviceInfo.device?.home_distance.toFixed(2) + ' m' }}</span>
              </a-tooltip>
            </a-col>
          </a-row>
          <a-row>
            <a-col span="6">
              <a-tooltip title="水平速度">
                <span>H.S</span>
                <span class="ml10">{{ !deviceInfo.device || deviceInfo.device?.horizontal_speed === str ? str :
                  deviceInfo.device?.horizontal_speed.toFixed(2) + ' m/s' }}</span>
              </a-tooltip>
            </a-col>
            <a-col span="6">
              <a-tooltip title="垂直速度">
                <span>V.S</span>
                <span class="ml10">{{ !deviceInfo.device || deviceInfo.device.vertical_speed === str ? str :
                  deviceInfo.device?.vertical_speed.toFixed(2) + ' m/s' }}</span>
              </a-tooltip>
            </a-col>
            <a-col span="6">
              <a-tooltip title="风速">
                <span>W.S</span>
                <span class="ml10">{{ !deviceInfo.device || deviceInfo.device.wind_speed === str ? str :
                  (deviceInfo.device?.wind_speed / 10).toFixed(2) + ' m/s' }}</span>
              </a-tooltip>
            </a-col>
          </a-row>
          <div>
            <a-row class="p5">
            <a-col span="6">
              <a-button :type="osdVisible.activeKey1" :disabled="!deviceInfo.device || deviceInfo.device?.mode_code === EModeCode.Disconnected || dockControlPanelVisible" size="small" @click="setactiveKey('activeKey1')">
              <!-- <a-button :type="osdVisible.activeKey1" size="small" @click="setactiveKey('activeKey1')"> -->
                飞行相机
              </a-button>
            </a-col>
            <a-col span="6">
              <a-button :type="osdVisible.activeKey2" :disabled="!deviceInfo.device || deviceInfo.device?.mode_code === EModeCode.Disconnected || dockControlPanelVisible" size="small" @click="setactiveKey('activeKey2')">
                M30T相机
              </a-button>
            </a-col>
          </a-row>
          </div>
          <div id="airlivediv" v-show="osdVisible.activeKey1==='primary'" style="height: 12.5rem;width: 100%;padding-left: 0;">
            <iframe id="airlive"  src="assets/html/airlive.html" scrolling="auto" frameborder="0" :style="{ width: '100%', height: '100%' }"></iframe>
          </div>
          <div id="cameralivediv" v-show="osdVisible.activeKey2==='primary'"  style="height: 12.5rem;width: 100%;padding-left: 0;">
            <iframe id="cameralive" src="assets/html/cameralive.html" scrolling="auto" frameborder="0" :style="{ width: '100%', height: '100%' }"></iframe>
          </div>
        </div>
      </div>

      <div class="battery-slide" v-if="deviceInfo.device && deviceInfo.device.battery.remain_flight_time !== 0"
        style="border: 1px solid red">
        <div style="background: #535759;" class="width-100"></div>
        <div class="capacity-percent" :style="{ width: deviceInfo.device.battery.capacity_percent + '%' }"></div>
        <div class="return-home" :style="{ width: deviceInfo.device.battery.return_home_power + '%' }"></div>
        <div class="landing" :style="{ width: deviceInfo.device.battery.landing_power + '%' }"></div>
        <div class="white-point" :style="{ left: deviceInfo.device.battery.landing_power + '%' }"></div>
        <div class="battery" :style="{ left: deviceInfo.device.battery.capacity_percent + '%' }">
          {{ Math.floor(deviceInfo.device.battery.remain_flight_time / 60) }}:
          {{ 10 > (deviceInfo.device.battery.remain_flight_time % 60) ? '0' :
            '' }}{{ deviceInfo.device.battery.remain_flight_time % 60 }}
        </div>
      </div>
      <!-- 飞行指令 -->
    </div>
  </div>
  <DroneControlPanel v-if="false" v-drag class="droneinfo" :sn="osdVisible.gateway_sn" :deviceInfo="deviceInfo" :payloads="osdVisible.payloads"></DroneControlPanel>
  <!-- v-if="osdVisible.visible && osdVisible.is_dock"  -->
  </div>
  </template>

<script lang="ts">
import { computed, defineComponent, onMounted, reactive, ref, watch } from 'vue'
import { message } from 'ant-design-vue'
import {
  generateLineContent,
  generatePointContent,
  generatePolyContent
} from '../utils/map-layer-utils'
import { postElementsReq } from '/@/api/layer'
import { MapDoodleType, MapElementEnum } from '/@/constants/map'
import { useGMapManage } from '/@/hooks/use-g-map'
import { useGMapCover } from '/@/hooks/use-g-map-cover'
import { useMouseTool } from '/@/hooks/use-mouse-tool'
import { getApp, getRoot } from '/@/root'
import { useMyStore } from '/@/store'
import { GeojsonCoordinate } from '/@/types/map'
import { MapDoodleEnum } from '/@/types/map-enum'
import { PostElementsBody } from '/@/types/mapLayer'
import { uuidv4 } from '/@/utils/uuid'
import { gcj02towgs84, wgs84togcj02 } from '/@/vendors/coordtransform'
import { deviceTsaUpdate } from '/@/hooks/use-g-map-tsa'
import {
  DeviceOsd, DeviceStatus, DockOsd, EGear, EModeCode, GatewayOsd, EDockModeCode,
  NetworkStateQualityEnum, NetworkStateTypeEnum, RainfallEnum, DroneInDockEnum
} from '/@/types/device'
import DockControlPanel from './g-map/DockControlPanel.vue'
import DroneControlPanel from './g-map/DroneControlPanel.vue'
import { useDockControl } from './g-map/use-dock-control'
import { useConnectMqtt } from './g-map/use-connect-mqtt'
import pin from '/@/assets/icons/pin-2d8cf0.svg'
import M30 from '/@/assets/icons/m30.png'
import {
  BorderOutlined, LineOutlined, CloseOutlined, ControlOutlined, TrademarkOutlined, ArrowDownOutlined,
  ThunderboltOutlined, SignalFilled, GlobalOutlined, HistoryOutlined, CloudUploadOutlined, RocketOutlined,
  FieldTimeOutlined, CloudOutlined, CloudFilled, FolderOpenOutlined, RobotFilled, ArrowUpOutlined, CarryOutOutlined
} from '@ant-design/icons-vue'
import { EDeviceTypeName, ELocalStorageKey,ERouterName } from '/@/types'

import { any } from 'vue-types'
import AMapLoader from '@amap/amap-jsapi-loader'
import { AMapConfig } from '/@/constants/index'
import jswebrtc from '/@/vendors/jswebrtc.min.js'
import { CURRENT_CONFIG as config } from '/@/api/http/config'
import { changeLivestreamLens, getLiveCapacity, setLivestreamQuality, startLivestream, stopLivestream, AIstartLives, AIstopLivestream } from '/@/api/manage'
declare const window: Window & { refreshWayline: any, WVJBCallbacks: any, setactiveKey:any, changeLivestreamLensfn:any, onStop:any, onstopAI:any, showairliveFile:any, onUpdateQuality:any, AIliveshow:any,liveshow:any,AIstartLivesfn:any,turnoff:any}

export default defineComponent({
  emits: ['refreshWayline'],
  components: {
    BorderOutlined,
    LineOutlined,
    CloseOutlined,
    ControlOutlined,
    TrademarkOutlined,
    ThunderboltOutlined,
    SignalFilled,
    GlobalOutlined,
    HistoryOutlined,
    CloudUploadOutlined,
    FieldTimeOutlined,
    CloudOutlined,
    CloudFilled,
    FolderOpenOutlined,
    RobotFilled,
    ArrowUpOutlined,
    ArrowDownOutlined,
    DockControlPanel,
    CarryOutOutlined,
    RocketOutlined,
    DroneControlPanel,
  },
  name: 'GMap',
  props: {},
  setup () {
    const workspaceId = ref(localStorage.getItem(ELocalStorageKey.WorkspaceId)!)
    const activeKey1 = ref('default')
    const activeKey2 = ref('default')
    const activeKey3 = ref('default')
    const videoId1 = ref()
    const videoId2 = ref()
    const videoId3 = ref()
    const dockdata = ref()
    const iframemsg1 = ref('')
    const iframemsg2 = ref('')
    const iframemsg3 = ref('')
    const droneSelected1 = ref()
    const droneSelected2 = ref()
    const droneSelected3 = ref()
    const cameraSelected1 = ref()
    const cameraSelected2 = ref()
    const cameraSelected3 = ref()
    const videoSelected1 = ref()
    const videoSelected2 = ref()
    const videoSelected3 = ref()
    const nonSwitchable1 = ref('normal')
    const nonSwitchable2 = ref('normal')
    const nonSwitchable3 = ref('normal')
    const videowebrtc = ref(null)
    const timestamp1 = ref()
    const timestamp2 = ref()
    const timestamp3 = ref()
    const timestampAI = ref()
    const useMouseToolHook = useMouseTool()
    const useGMapManageHook = useGMapManage()
    const deviceTsaUpdateHook = deviceTsaUpdate()
    const root = getRoot()

    const mouseMode = ref(false)
    const store = useMyStore()
    const state = reactive({
      currentType: '',
      coverIndex: 0
    })
    const str: string = '--'
    const deviceInfo = reactive({
      gateway: {
        capacity_percent: str,
        transmission_signal_quality: str,
      } as GatewayOsd,
      dock: {

      } as DockOsd,
      device: {
        gear: -1,
        mode_code: EModeCode.Disconnected,
        height: str,
        home_distance: str,
        horizontal_speed: str,
        vertical_speed: str,
        wind_speed: str,
        wind_direction: str,
        elevation: str,
        position_state: {
          gps_number: str,
          is_fixed: 0,
          rtk_number: str
        },
        battery: {
          capacity_percent: str,
          landing_power: str,
          remain_flight_time: 0,
          return_home_power: str,
        },
        latitude: 0,
        longitude: 0,
      } as DeviceOsd
    })
    const shareId = computed(() => {
      return store.state.layerBaseInfo.share
    })
    const defaultId = computed(() => {
      return store.state.layerBaseInfo.default
    })
    const drawVisible = computed(() => {
      return store.state.drawVisible
    })
    const osdVisible = computed(() => {
      return store.state.osdVisible
    })
    watch(
      () => store.state.deviceStatusEvent ?? '',
      data => {
        if (Object.keys(data.deviceOnline).length !== 0) {
          deviceTsaUpdateHook.initMarker(data.deviceOnline.domain, data.deviceOnline.device_callsign, data.deviceOnline.sn)
          store.state.deviceStatusEvent.deviceOnline = {} as DeviceStatus
        }
        if (Object.keys(data.deviceOffline).length !== 0) {
          deviceTsaUpdateHook.removeMarker(data.deviceOffline.sn)
          if ((data.deviceOffline.sn === osdVisible.value.sn) || (osdVisible.value.is_dock && data.deviceOffline.sn === osdVisible.value.gateway_sn)) {
            osdVisible.value.visible = false
            store.commit('SET_OSD_VISIBLE_INFO', osdVisible)
          }
          store.state.deviceStatusEvent.deviceOffline = {}
        }
      },
      {
        deep: true
      }
    )

    watch(() => store.state.deviceState, data => {
      if (data.currentType === EDeviceTypeName.Gateway && data.gatewayInfo[data.currentSn]) {
        deviceTsaUpdateHook.moveTo(data.currentSn, data.gatewayInfo[data.currentSn].longitude, data.gatewayInfo[data.currentSn].latitude)
        if (osdVisible.value.visible && osdVisible.value.gateway_sn !== '') {
          deviceInfo.gateway = data.gatewayInfo[osdVisible.value.gateway_sn]
        }
      }
      if (data.currentType === EDeviceTypeName.Aircraft && data.deviceInfo[data.currentSn]) {
        deviceTsaUpdateHook.moveTo(data.currentSn, data.deviceInfo[data.currentSn].longitude, data.deviceInfo[data.currentSn].latitude)
        if (osdVisible.value.visible && osdVisible.value.sn !== '') {
          deviceInfo.device = data.deviceInfo[osdVisible.value.sn]
        }
      }
      if (data.currentType === EDeviceTypeName.Dock && data.dockInfo[data.currentSn]) {
        deviceTsaUpdateHook.initMarker(EDeviceTypeName.Dock, data.currentSn, data.currentSn, data.dockInfo[data.currentSn].basic_osd?.longitude, data.dockInfo[data.currentSn].basic_osd?.latitude)        
        if (osdVisible.value.visible && osdVisible.value.is_dock && osdVisible.value.gateway_sn !== '') {
          deviceInfo.dock = data.dockInfo[osdVisible.value.gateway_sn]
          deviceInfo.device = data.deviceInfo[deviceInfo.dock.basic_osd?.sub_device?.device_sn ?? osdVisible.value.sn]
        }
        if(osdVisible.value.gateway_sn===data.currentSn){
          console.log(data.dockInfo[data.currentSn],'data');
          return
          
          mapdata.maps.setZoomAndCenter(18, [flyPoinList.value[pointIndex.value].Lnglat.lng, flyPoinList.value[pointIndex.value].Lnglat.lat])
        }
      }
      // 有航线要飞           
      if(data.dockInfo[data.currentSn]?.link_osd?.flighttask_step_code===1){
        deviceTsaUpdateHook.getLastWaylineByDockSnfn(data.currentSn)
      }
    }, {
      deep: true
    })

    watch(
      () => store.state.wsEvent,
      newData => {
        const useGMapCoverHook = useGMapCover()
        const event = newData
        let exist = false
        if (Object.keys(event.mapElementCreat).length !== 0) {
          console.log(event.mapElementCreat)
          const ele = event.mapElementCreat
          store.state.Layers.forEach(layer => {
            layer.elements.forEach(e => {
              if (e.id === ele.id) {
                exist = true
                console.log('true')
              }
            })
          })
          if (exist === false) {
            setLayers({
              id: ele.id,
              name: ele.name,
              resource: ele.resource
            })

            updateCoordinates('wgs84-gcj02', ele)
            useGMapCoverHook.init2DPin(
              ele.name,
              ele.resource.content.geometry.coordinates,
              ele.resource.content.properties.color,
              {
                id: ele.id,
                name: ele.name
              }
            )
          }
          store.state.wsEvent.mapElementCreat = {}
        }
        if (Object.keys(event.mapElementUpdate).length !== 0) {
          console.log(event.mapElementUpdate)
          console.log('该功能还未实现，请开发商自己增加')
          store.state.wsEvent.mapElementUpdate = {}
        }
        if (Object.keys(event.mapElementDelete).length !== 0) {
          console.log(event.mapElementDelete)
          console.log('该功能还未实现，请开发商自己增加')
          store.state.wsEvent.mapElementDelete = {}
        }
      },
      {
        deep: true
      }
    )

    // 进入驾驶舱
const toCockpit=()=> {
  root.$router.push({
    path: ERouterName.COCKPIT,
    query: {
      // osdVisible: JSON.stringify(osdVisible.value),
      device_sn: osdVisible.value.sn,
      workspace_id: workspaceId.value,
      gateway_sn: osdVisible.value.gateway_sn,
      wayline_id:1,
    },
  });
}
    function draw (type: MapDoodleType, bool: boolean) {
      state.currentType = type
      useMouseToolHook.mouseTool(type, getDrawCallback)
      mouseMode.value = bool
    }
    // 按钮选中
     function setactiveKey (obj:string) {
      // const liveURL = config.rtmpURL
      const airlivedivdata = document.getElementById('airlivediv')
      const cameralivedivdata = document.getElementById('cameralivediv')
      const docklivedivdata = document.getElementById('docklivediv')
      if (obj === 'activeKey1') {
        if (osdVisible.value.activeKey1 === 'primary' || airlivedivdata?.style.display === 'block') {
          osdVisible.value.activeKey1 = 'default'
          onStop('activeKey1')
          // iframemsg1.value=''
        } else {
          // iframemsg1.value='<span> <iframe id="airlive" src="assets/html/airlive.html" scrolling="auto" frameborder="0"></iframe></span>';
          osdVisible.value.activeKey2 = 'default'
          osdVisible.value.activeKey1 = 'primary'
          onStart('activeKey1')
        }
      }
      if (obj === 'activeKey2') {
        if (osdVisible.value.activeKey2 === 'primary' || cameralivedivdata?.style.display === 'block') {
          osdVisible.value.activeKey2 = 'default'
          onStop('activeKey2')
          onstopAI()
          // iframemsg2.value=''
        } else {
          // iframemsg2.value='<span> <iframe id="cameralive" src="assets/html/cameralive.html" scrolling="auto" frameborder="0"></iframe></span>';
          osdVisible.value.activeKey1 = 'default'
          osdVisible.value.activeKey2 = 'primary'
          onStart('activeKey2')
          // console.log(videoId2.value, 'videoId2.value ')

          // const url = config.webRTCURL + 'index/api/webrtc?app=uav&stream=' + videoId2.value + '/' + timestamp2.value + '&type=play'// 飞机视频的接口
          // showairliveFile('cameralive', url, '')
        }
      }
      if (obj === 'activeKey3') {
        if (osdVisible.value.activeKey3 === 'primary' || docklivedivdata?.style.display === 'block') {
          osdVisible.value.activeKey3 = 'default'
          onStop('activeKey3')
          // iframemsg3.value=''
        } else {
          // iframemsg3.value='<span> <iframe id="docklive" src="assets/html/docklive.html" scrolling="auto" frameborder="0"></iframe></span>';
          osdVisible.value.activeKey3 = 'primary'
          onStart('activeKey3')
          // const url = config.webRTCURL + 'index/api/webrtc?app=uav&stream=' + videoId3.value + '/' + timestamp3.value + '&type=play'// 飞机视频的接口

          // showairliveFile('docklive', url, '')
        }
      }
      // store.commit('SET_OSD_VISIBLE_INFO', osdVisible)
    }
    // 地图试试定位
    function positionfn (path:any) {
      let AMap = root.$aMap
      const patharry = [[116.403322, 39.920255], [116.420703, 39.897555], [116.321354, 39.896436], [116.407012, 39.992093], [116.403322, 39.920255]]
      const polyline = new AMap.Polyline({
      path: path,
      isOutline: true,
      outlineColor: '#ffeeff',
      borderWeight: 0,
      strokeColor: '#11BC82',
      strokeOpacity: 1,
      strokeWeight: 10,
      // 折线样式还支持 'dashed solid'
      strokeStyle: 'solid',
      // strokeStyle是dashed时有效
      strokeDasharray: [10, 5],
      lineJoin: 'round',
      lineCap: 'round',
      zIndex: 50,
      showDir: true,
    })
    polyline.setMap(root.$map)
    root.$map.setFitView()
      // }, 2000)
    }
    // 直播传值
    function showairliveFile (name:string, url:string, urlAI:string) {
      // console.log(url, 'url传值')

      const dataname:any = document.getElementById(name)
      if (dataname && dataname.contentWindow) {
        dataname?.contentWindow?.start_play(name, url, urlAI)
      }
    }
    // 获取在线机库
    const dockdatafn = async () => {
      await  getLiveCapacity({})
        .then(res => {
          if (res.data) {
            dockdata.value = res.data
          }
        })
    }
    // 开始判断对应按钮推流
     const onStart = async (obj:any) => {
      const res11 = await getLiveCapacity({})
        if (res11.data) {
          dockdata.value = res11.data
        }
      const paramAI = {}
      const liveURL = config.rtmpURL
      // 机库的
      if (obj === 'activeKey3') {
       let param = {}
        timestamp3.value = new Date().getTime().toString()
        for (let i = 0; i < dockdata.value.length; i++) {
          // dockdata.value[i].sn === osdVisible.value.gateway_sn
          if (dockdata.value[i].sn === osdVisible.value.gateway_sn) {
            droneSelected3.value = dockdata.value[i].sn
            cameraSelected3.value = dockdata.value[i].cameras_list[0].index
            nonSwitchable3.value = dockdata.value[i].cameras_list[0].videos_list[0].type
            videoId3.value =
            droneSelected3.value + '/' + cameraSelected3.value + '/' + (videoSelected3.value || nonSwitchable3.value + '-0')
          }
        }
         param = {
          url: liveURL + videoId3.value + '/' + timestamp3.value,
          video_id: videoId3.value,
          url_type: 1, // {"0":"声网Agora","1":"RTMP","2":"RTSP","3":"GB28181"}
          video_quality: 1, // {"0":"自适应","1":"流畅","2":"标清","3":"高清","4":"超清"}
        }
        startLivestreamfn(param)//推流
        const url = config.webRTCURL + 'index/api/webrtc?app=uav&stream=' + videoId3.value + '/' + timestamp3.value + '&type=play'// 飞机视频的接口
        showairliveFile('docklive', url, '')//拉流
      }
      // 飞机相机
      if (obj === 'activeKey1') {
        timestamp1.value = new Date().getTime().toString()
        for (let i = 0; i < dockdata.value.length; i++) {
          // console.log(dockdata.value[i].sn,osdVisible.value.sn,'111111');
          if (dockdata.value[i].sn === osdVisible.value.sn) {
            for (let y = 0; y < dockdata.value[i].cameras_list.length; y++) {
              if (dockdata.value[i].cameras_list[y].name === 'FPV') {
                const y = 0
                droneSelected1.value = dockdata.value[i].sn
                cameraSelected1.value = dockdata.value[i].cameras_list[y].index
                nonSwitchable1.value = dockdata.value[i].cameras_list[y].videos_list[0].type
                videoId1.value =
                droneSelected1.value + '/' + cameraSelected1.value + '/' + (videoSelected1.value || nonSwitchable1.value + '-0')
              }
            }
          }
        }
        // url: liveURL + videoId1.value + '_' + timestamp1.value,
        const param = {
          url: liveURL + videoId1.value + '/' + timestamp1.value,
          video_id: videoId1.value,
          url_type: 1, // {"0":"声网Agora","1":"RTMP","2":"RTSP","3":"GB28181"}
          video_quality: 1, // {"0":"自适应","1":"流畅","2":"标清","3":"高清","4":"超清"}
        }
        console.log(param,'111111111111111111111111111111111');
        
        startLivestreamfn(param)//推流
        const url = config.webRTCURL + 'index/api/webrtc?app=uav&stream=' + videoId1.value + '/' + timestamp1.value + '&type=play'
        showairliveFile('airlive', url, '')//拉流
      }
      // M30T/M3TD
      if (obj === 'activeKey2') {
        timestamp2.value = new Date().getTime().toString()
        timestampAI.value = new Date().getTime().toString()
        for (let i = 0; i < dockdata.value.length; i++) {
          if (dockdata.value[i].sn === osdVisible.value.sn) {
            for (let y = 0; y < dockdata.value[i].cameras_list.length; y++) {
              if (dockdata.value[i].cameras_list[y].name === 'M30T Camera'||dockdata.value[i].cameras_list[y].name === 'M3TD Camera') {
                droneSelected3.value = dockdata.value[i].sn
                cameraSelected2.value = dockdata.value[i].cameras_list[y].index
                nonSwitchable2.value = dockdata.value[i].cameras_list[y].videos_list[0].index
                videoId2.value =
                          droneSelected3.value + '/' + cameraSelected2.value + '/' + (videoSelected1.value || nonSwitchable2.value)
              }
            }
          }
        }
        const param = {
          url: liveURL + videoId2.value + '/' + timestamp2.value,
          video_id: videoId2.value,
          url_type: 1, // {"0":"声网Agora","1":"RTMP","2":"RTSP","3":"GB28181"}
          video_quality: 1, // {"0":"自适应","1":"流畅","2":"标清","3":"高清","4":"超清"}
        }
        startLivestreamfn(param)// 获取推流方法
        const url = config.webRTCURL + 'index/api/webrtc?app=uav&stream=' + videoId2.value + '/' + timestamp2.value + '&type=play'// 飞机视频的接口
        showairliveFile('cameralive', url, '')//拉流
        const paramsize= '720x540'
        AIstartLivesfn(paramsize,0)// 获取ai推流方法
      }
    }
    // 获取推流方法
    const startLivestreamfn = (param:any) => {
      // console.log(param, '推流')
      // log
      startLivestream(param)
        .then(res => {
          console.log(res.data, '成功')
        })
        .catch(err => {
          console.error(err)
        })
    }
    
    // 获取AI推流的方法
    const AIstartLivesfn = (paramsize:any,obj:any) => {
      const paramAI = new FormData()
        paramAI.append('weights', 'weights/yolov5s.pt')
        paramAI.append('source', config.aiSourceRtspURL + videoId2.value + '/' + timestampAI.value)
        paramAI.append('output', config.aiOutPutRtmpURL + videoId2.value + '/' + timestampAI.value)
        paramAI.append('size', paramsize)
        AIstartLives(paramAI).then(res => {
          // debugger
          if(res==='ok'){
            const dataname:any = document?.getElementById('cameralive')
            if (dataname && dataname.contentWindow) {
              dataname?.contentWindow?.closeloadingfn()
              if(obj===1){
                setTimeout(()=>{ dataname?.contentWindow?.start_on(AIliveshow(),'cameralive')},3000);
              }
            }
          }
          console.log(res,'推流成功');
        })
    }
    // 获取AI拉流的值
    const AIliveshow = () => {
      return config.webRTCURL + 'index/api/webrtc?app=ai&stream=' + videoId2.value + '/' + timestampAI.value + '&type=play'
    }
    // 普通拉流的值
    const liveshow=()=>{
      return config.webRTCURL + 'index/api/webrtc?app=uav&stream=' + videoId2.value + '/' + timestamp2.value + '&type=play'// 飞机视频的接口
    }
    const onStop = (obj:any) => {
      if (obj === 'activeKey1') {      
        stopLivestream({
          video_id: videoId1.value
        }).then(res => {
          // debugger
          if (res.code === 0) {
            message.success('关闭成功')
          }
        })
      }
      if (obj === 'activeKey2') {
        stopLivestream({
          video_id: videoId2.value
        }).then(res => {
          if (res.code === 0) {
            message.success('关闭成功')
          }
        })
      }
      if (obj === 'activeKey3') {
        stopLivestream({
          video_id: videoId3.value
        }).then(res => {
          if (res.code === 0) {
            message.success('关闭成功')
          }
        })
      }
    }
    const onstopAI = () => {
      AIstopLivestream().then(res => {
        // if (res.code === 0) {
        //   message.success(res.message)
        // }
      })
    }
    // 切换摄像头
    const changeLivestreamLensfn = (type:any) => {
      changeLivestreamLens({
        video_id: videoId2.value,
        video_type: type,
      }).then(res => {
        // console.log(res, '切换后es.code')

        if (res.code === 0) {
          message.success('切换成功')
        }
      })
    }
    // 切换清晰度
    const onUpdateQuality = (type:any, video_quality:any) => {
      let video_id = ''
      if (type === 'airlive') {
        video_id = videoId1.value
      } else if (type === 'cameralive') {
        video_id = videoId2.value
      } else if (type === 'docklive') {
        video_id = videoId3.value
      }
      // console.log(video_id, 'video_id切换清晰度')

      setLivestreamQuality({
        video_id: video_id,
        video_quality: video_quality
      }).then(res => {
        if (res.code === 0) {
          message.success('切换清晰度切换成功')
        }
      })
    }
    // 全部关闭
    function turnoff(name:any){   
      if(name==='docklive'&& osdVisible.value.activeKey3=== 'primary'){
        osdVisible.value.activeKey3 = 'default'
        message.error('信号中断，请重新打开')
      }
      if(name=='cameralive' && osdVisible.value.activeKey2=== 'primary'){
        osdVisible.value.activeKey2 = 'default'
        message.error('信号中断，请重新打开')
      }
      if(name=='airlive' && osdVisible.value.activeKey1=== 'primary'){
        osdVisible.value.activeKey1 = 'default'
        message.error('信号中断，请重新打开')
      }
    }
    // dock 控制指令
    const {
      sendDockControlCmd,
      dockDebugOnOff,
      dockControlPanelVisible,
      setDockControlPanelVisible,
      onCloseControlPanel,
    } = useDockControl()

    // 连接或断开drc
    useConnectMqtt()
    const mapdata = reactive({
      app: getApp(),
      takeOffMarket: null,
      dockMarker: null,
      param: {},
      aMaps: null,
      maps: null
    })
    onMounted(() => {
      const app = getApp()
      useGMapManageHook.globalPropertiesConfig(app,mapdata)
      setInterval(() => {
        // console.info(deviceInfo.dock)
      }, 1000)
      dockdatafn()
    })

    function getDrawCallback ({ obj }) {
      switch (state.currentType) {
        case MapDoodleEnum.PIN:
          postPinPositionResource(obj)
          break
        case MapDoodleEnum.POLYLINE:
          postPolylineResource(obj)
          break
        case MapDoodleEnum.POLYGON:
          postPolygonResource(obj)
          break
        default:
          break
      }
    }
    async function postPinPositionResource (obj) {
      const req = getPinPositionResource(obj)
      setLayers(req)
      const coordinates = req.resource.content.geometry.coordinates
      updateCoordinates('gcj02-wgs84', req);
      (req.resource.content.geometry.coordinates as GeojsonCoordinate).push((coordinates as GeojsonCoordinate)[2])
      const result = await postElementsReq(shareId.value, req)
      obj.setExtData({ id: req.id, name: req.name })
      store.state.coverList.push(obj)
      // console.log(store.state.coverList)
    }
    async function postPolylineResource (obj) {
      const req = getPolylineResource(obj)
      setLayers(req)
      updateCoordinates('gcj02-wgs84', req)
      const result = await postElementsReq(shareId.value, req)
      obj.setExtData({ id: req.id, name: req.name })
      store.state.coverList.push(obj)
      // console.log(store.state.coverList)
    }
    async function postPolygonResource (obj) {
      const req = getPoygonResource(obj)
      setLayers(req)
      updateCoordinates('gcj02-wgs84', req)
      const result = await postElementsReq(shareId.value, req)
      obj.setExtData({ id: req.id, name: req.name })
      store.state.coverList.push(obj)
      // console.log(store.state.coverList)
    }

    function getPinPositionResource (obj) {
      const position = obj.getPosition()
      const resource = generatePointContent(position)
      const name = obj._originOpts.title
      const id = uuidv4()
      return {
        id,
        name,
        resource
      }
    }
    function getPolylineResource (obj) {
      const path = obj.getPath()
      const resource = generateLineContent(path)
      const { name, id } = getBaseInfo(obj._opts)
      return {
        id,
        name,
        resource
      }
    }
    function getPoygonResource (obj) {
      const path = obj.getPath()
      const resource = generatePolyContent(path)
      const { name, id } = getBaseInfo(obj._opts)
      return {
        id,
        name,
        resource
      }
    }
    function getBaseInfo (obj) {
      const name = obj.title
      const id = uuidv4()
      return { name, id }
    }
    function setLayers (resource: PostElementsBody) {
      const layers = store.state.Layers
      const layer = layers.find(item => item.id.includes(shareId.value))
      // layer.id = 'private_layer' + uuidv4()
      // layer?.elements.push(resource)
      if (layer?.elements) {
        ; (layer?.elements as any[]).push(resource)
      }
      console.log('layers', layers)
      store.commit('SET_LAYER_INFO', layers)
    }
    function updateCoordinates (transformType: string, element: any) {
      const geoType = element.resource?.content.geometry.type
      const type = element.resource?.type as number
      if (element.resource) {
        if (MapElementEnum.PIN === type) {
          const coordinates = element.resource?.content.geometry
            .coordinates as GeojsonCoordinate
          if (transformType === 'wgs84-gcj02') {
            const transResult = wgs84togcj02(
              coordinates[0],
              coordinates[1]
            ) as GeojsonCoordinate
            element.resource.content.geometry.coordinates = transResult
          } else if (transformType === 'gcj02-wgs84') {
            const transResult = gcj02towgs84(
              coordinates[0],
              coordinates[1]
            ) as GeojsonCoordinate
            element.resource.content.geometry.coordinates = transResult
          }
        } else if (MapElementEnum.LINE === type && geoType === 'LineString') {
          const coordinates = element.resource?.content.geometry
            .coordinates as GeojsonCoordinate[]
          if (transformType === 'wgs84-gcj02') {
            coordinates.forEach(coordinate => {
              coordinate = wgs84togcj02(
                coordinate[0],
                coordinate[1]
              ) as GeojsonCoordinate
            })
          } else if (transformType === 'gcj02-wgs84') {
            coordinates.forEach(coordinate => {
              coordinate = gcj02towgs84(
                coordinate[0],
                coordinate[1]
              ) as GeojsonCoordinate
            })
          }
          element.resource.content.geometry.coordinates = coordinates
        } else if (MapElementEnum.LINE === type && geoType === 'Polygon') {
          const coordinates = element.resource?.content.geometry
            .coordinates[0] as GeojsonCoordinate[]

          if (transformType === 'wgs84-gcj02') {
            coordinates.forEach(coordinate => {
              coordinate = wgs84togcj02(
                coordinate[0],
                coordinate[1]
              ) as GeojsonCoordinate
            })
          } else if (transformType === 'gcj02-wgs84') {
            coordinates.forEach(coordinate => {
              coordinate = gcj02towgs84(
                coordinate[0],
                coordinate[1]
              ) as GeojsonCoordinate
            })
          }
          element.resource.content.geometry.coordinates = [coordinates]
        }
      }
    }
    function getWaylineRoute () {
      return root.$route.name === 'path'
    }
    return {
      draw,
      showairliveFile,
      positionfn,
      mouseMode,
      drawVisible,
      osdVisible,
      getWaylineRoute,
      setactiveKey,
      pin,
      state,
      M30,
      deviceInfo,
      EGear,
      EModeCode,
      str,
      EDockModeCode,
      dockDebugOnOff,
      NetworkStateTypeEnum,
      NetworkStateQualityEnum,
      RainfallEnum,
      DroneInDockEnum,
      activeKey1,
      activeKey2,
      activeKey3,
      toCockpit,
      onStart,
      onStop,
      onstopAI,
      dockdatafn,
      startLivestreamfn, // 推流
      AIstartLivesfn, // ai推流
      changeLivestreamLensfn,
      onUpdateQuality,
      AIliveshow,//AI拉流的值
      liveshow,//普通拉流的值
      setDockControlPanelVisible,
      onCloseControlPanel,
      dockControlPanelVisible,
      turnoff,//全部关闭
      iframemsg1,
      iframemsg2,
      iframemsg3,
    }
  },
  mounted () {
    window.refreshWayline = this.refreshWayline
    window.setactiveKey = this.setactiveKey// 切换视频开启关闭
    window.changeLivestreamLensfn = this.changeLivestreamLensfn// 切换广角/红外
    window.onStop = this.onStop// 关闭视频接口
    window.onstopAI = this.onstopAI// 关闭AI视频接口
    window.showairliveFile = this.showairliveFile// 刷新接口
    window.onUpdateQuality = this.onUpdateQuality // 切换清晰度
    window.AIliveshow = this.AIliveshow// 获取AI拉流
    window.liveshow = this.liveshow//拉流
    window.AIstartLivesfn = this.AIstartLivesfn //获取AI推流
    window.turnoff = this.turnoff//关闭全部视频窗口
  },
  methods: {
    refreshWayline () {
      this.$emit('refreshWayline')
    },
    showRouteFile (pointData:any) {
      const routePlanning: any = document?.getElementById('routePlanning')
      if (routePlanning && routePlanning.contentWindow) {
        routePlanning.contentWindow.showRouteFile(pointData)
      }
    }
  }
})
</script>

<style lang="scss" scoped>
.droneinfo{
  position: fixed;
  // z-index: 10000;

  top:0;
  left: 55rem;
  background: #000;opacity: 0.8;
}
.Gmap{
  width: 100%;
  height: 100%;
  position: relative;
}
.bgblue{
  background: blue;
  color: #fff;
}
.ant-tabs-nav .ant-tabs-tab{
  color: #fff !important;
}
.ant-tabs-ink-bar{
  background-color: transparent !important;
}

.ant-tabs-top .ant-tabs-ink-bar-animated:before{
  content: " ";
  background-color: red;
  width: 50px;
  height: 2px;
  position: absolute;
  margin: auto;
  margin-left: 25%;

}
.g-map-wrapper {
  height: 100%;
  width: 100%;

  .g-action-panle {
    position: absolute;
    top: 16px;
    right: 16px;

    .g-action-item {
      width: 28px;
      height: 28px;
      background: white;
      color: $primary;
      border-radius: 2px;
      line-height: 28px;
      text-align: center;
      margin-bottom: 2px;
    }

    .g-action-item:hover {
      border: 1px solid $primary;
      border-radius: 2px;
    }
  }

  .selection {
    border: 1px solid $primary;
    border-radius: 2px;
  }

  // antd button 光晕
  &:deep(.ant-btn) {
    &::after {
      display: none;
    }
  }
}

.osd-panel {
  position: absolute;
  left: 0px;
  top: 50px;
  width: 480px;
  background: black;
  color: white;
  border-radius: 2px;
  // opacity: 0.9;
  background: (0 0 0 0.7);
}

.osd>div {
  margin-top: 5px;
  padding-left: 5px;
}

.circle {
  border-radius: 50%;
  width: 10px;
  height: 10px;
}

.battery-slide {
  .capacity-percent {
    background: #00ee8b;
  }

  .return-home {
    background: #ff9f0a;
  }

  .landing {
    background: #f5222d;
  }

  .white-point {
    width: 4px;
    height: 4px;
    border-radius: 50%;
    background: white;
    bottom: -0.5px;
  }

  .battery {
    background: #141414;
    color: #00ee8b;
    margin-top: -10px;
    height: 20px;
    width: auto;
    border-left: 1px solid #00ee8b;
    padding: 0 5px;
  }
}

.battery-slide>div {
  position: absolute;
  min-height: 2px;
  border-radius: 2px;
}
</style>
<style lang="scss" scoped>
.g-map-wrapper {
  height: 100%;
  width: 100%;

  .g-action-panle {
    display: none;
    position: absolute;
    top: 16px;
    right: 16px;

    .g-action-item {
      padding-top: 8px;
    }
  }
}
</style>
<style lang="scss">
.amap-logo {
  opacity: 0;
}

.amap-copyright {
  opacity: 0;
}
.amap-marker-label{
background: transparent;
border: 0;
color: #fff;
font-size: 24px;
}
#docklive,#cameralive,#airlive{
  width: 100%;
  height: 100%;
}
</style>
